// ChoiceButton.tsx
import React from 'react';
import { cn } from '@/lib/utils';

interface ChoiceButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  indicator?: string;
  selected?: boolean;
}

export function ChoiceButton({
  children,
  indicator,
  selected,
  className,
  ...props
}: ChoiceButtonProps) {
  return (
    <button
    className={cn(
      'w-full p-6 font-mono rounded-lg text-left focus-ring',
      'border-2 border-default',
      'hover:border-choice-button-hover',
      'hover:bg-choice-button-hover',
      'hover:translate-x-1',
      selected && 'border-accent/40 dark:border-accent/40 bg-black/5 dark:bg-white/5 translate-x-1',
      className
      )}
      role="option"
      aria-selected={selected}
      data-selected={selected}
      tabIndex={0}
      {...props}
    >
      <div className="flex gap-4">
        {indicator && (
          <div className="flex-none w-8 h-8 flex items-center justify-center rounded-lg border-2 border-accent/40 bg-black/5 dark:bg-white/5">
            <span className="text-sm font-bold text-accent/80 dark:text-accent/80">
              {indicator}
            </span>
          </div>
        )}
        <div className="flex-1 min-w-0">
          {children}
        </div>
      </div>
    </button>
  );
}

interface ChoiceGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export function ChoiceGroup({
  children,
  className,
  ...props
}: ChoiceGroupProps) {
  return (
    <div
      className={cn(
        'space-y-2',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}
import { useState, useEffect } from 'react';
import { cn } from '@/lib/utils';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import type { Components } from 'react-markdown';

interface TerminalTextProps {
  content: string;
  className?: string;
  showCursor?: boolean;
  animate?: boolean;
  speed?: 'slow' | 'normal' | 'fast';
  onComplete?: () => void;
}

// Clean markdown content - keep the content between markdown blocks
function cleanMarkdownContent(content: string): string {
  const markdownRegex = /```markdown\n([\s\S]*?)```/;
  const match = content.match(markdownRegex);
  return match?.[1] ?? content;
}

export function TerminalText({
  content,
  className = '',
  showCursor = false,
  animate = false,
  speed = 'normal',
  onComplete
}: TerminalTextProps) {
  const [displayedText, setDisplayedText] = useState(animate ? '' : cleanMarkdownContent(content));
  const [isComplete, setIsComplete] = useState(!animate);

  // Animate text display if animate is true
  useEffect(() => {
    if (!animate) {
      setDisplayedText(cleanMarkdownContent(content));
      setIsComplete(true);
      onComplete?.();
      return;
    }

    let mounted = true;
    let timeouts: number[] = [];

    const renderText = async () => {
      setDisplayedText('');
      setIsComplete(false);

      const baseDelay = speed === 'slow' ? 50 : speed === 'normal' ? 30 : 10;

      for (let i = 0; i < content.length; i++) {
        if (!mounted) break;

        const delay = content[i] === '\n' ? baseDelay * 2 : baseDelay;
        await new Promise(resolve => {
          const timeout = window.setTimeout(resolve, delay);
          timeouts.push(timeout);
        });

        setDisplayedText(prev => prev + content[i]);

        if (i === content.length - 1) {
          setIsComplete(true);
          onComplete?.();
        }
      }
    };

    renderText();

    return () => {
      mounted = false;
      timeouts.forEach(clearTimeout);
    };
  }, [content, speed, animate, onComplete]);

  // Markdown components with our typography
  const components: Components = {
    h1: ({children}) => (
      <h1 className={cn(
        'font-bold mt-6 mb-2',
        'text-lg leading-relaxed',
        'border-b border-black/10 dark:border-white/10 pb-2'
      )}>
        {children}
      </h1>
    ),
    h2: ({children}) => (
      <h2 className={cn(
        'font-bold mt-4 mb-2',
        'text-base leading-relaxed'
      )}>
        {children}
      </h2>
    ),
    h3: ({children}) => (
      <h3 className={cn(
        'font-bold mt-3 mb-2',
        'text-base leading-relaxed'
      )}>
        {children}
      </h3>
    ),
    p: ({children}) => (
      <p className="my-3 leading-relaxed">{children}</p>
    ),
    ul: ({children}) => (
      <ul className="my-2 pl-6 space-y-1 list-disc [&>li]:relative">{children}</ul>
    ),
    ol: ({children}) => (
      <ol className="my-2 pl-9 space-y-1 list-decimal [&>li]:relative">{children}</ol>
    ),
    li: ({children}) => (
      <li className="leading-relaxed pl-1 [&>p]:my-0">
        <div className="[text-wrap:balance]">{children}</div>
      </li>
    ),
    table: ({children}) => (
      <div className="overflow-x-auto my-4">
        <table className={cn(
          'w-full border-collapse',
          'border border-black/10 dark:border-white/10',
          'text-sm leading-base'
        )}>
          {children}
        </table>
      </div>
    ),
    th: ({children}) => (
      <th className={cn(
        'px-4 py-2 text-left',
        'bg-black/5 dark:bg-white/5',
        'border border-black/10 dark:border-white/10',
        'text-xs font-medium uppercase tracking-wide'
      )}>
        {children}
      </th>
    ),
    td: ({children}) => (
      <td className={cn(
        'px-4 py-2',
        'border border-black/10 dark:border-white/10',
        'text-sm'
      )}>
        {children}
      </td>
    ),
    code: ({className: codeClassName, children}) => {
      const isBlock = codeClassName?.includes('language-');
      
      return isBlock ? (
        <pre className={cn(
          'my-4 p-4',
          'bg-black/5 dark:bg-white/5',
          'border border-black/10 dark:border-white/10',
          'overflow-x-auto'
        )}>
          <code className="font-mono text-sm block">
            {children}
          </code>
        </pre>
      ) : (
        <code className={cn(
          'font-mono text-sm',
          'px-1.5 py-0.5 mx-0.5',
          'bg-black/5 dark:bg-white/5',
          'border border-black/10 dark:border-white/10'
        )}>
          {children}
        </code>
      );
    }
  };

  return (
    <div className={cn(
      'font-mono text-base leading-relaxed',
      'transition-opacity duration-200',
      !isComplete && 'opacity-90',
      className
    )}>
      {animate ? (
        // Simple text with cursor for animated loading states
        <span className="whitespace-pre-wrap">
          {displayedText}
          {showCursor && !isComplete && (
            <span 
              className={cn(
                'inline-block w-2 h-5 -mb-0.5 ml-0.5',
                'bg-black dark:bg-white',
                'animate-[blink_1s_ease-in-out_infinite]'
              )}
              aria-hidden="true"
            />
          )}
        </span>
      ) : (
        // Full markdown rendering for content
        <Markdown
          remarkPlugins={[remarkGfm]}
          components={components}
          className={cn(
            'prose dark:prose-invert max-w-none',
            'prose-headings:font-mono',
            'prose-p:leading-relaxed prose-p:my-3',
            'prose-pre:p-0 prose-pre:bg-transparent',
            'prose-code:font-mono prose-code:text-sm',
            'prose-strong:font-bold prose-strong:text-inherit',
            'prose-em:italic prose-em:text-inherit',
            'prose-ul:list-disc prose-ol:list-decimal'
          )}
        >
          {displayedText}
        </Markdown>
      )}
    </div>
  );
}